/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import Loader from '../../components/Loader/Loader';
import OrderTrackingWidget from "../../components/OrderTrackingWidget/OrderTrackingWidget";
import styles from './OrderDetails.module.css';
import * as orderRepo from '../../shared/repos/graphql/order';

import { orderTrackingWidgetModes, orderTypeIds } from '../../shared/constants/order';
import OrderTotalsWidget from '../../components/OrderTotalsWidget/OrderTotalsWidget';
import OrderDetailsWidget from '../../components/OrderDetailsWidgets/OrderDetailsWidget';

class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            order: {}
        };
    }

    componentDidMount() {
        this.getOrder();
    }

    orderInProgress = () => {
        const { order } = this.state;

        if (order.isCancelled || order.isRefunded || order.isStale) {
          return false;
        }

        return order.status?.status < 5;
    }

    getOrder = async () => {
        const { match } = this.props;

        const response = await orderRepo.getOrder(match.params.id);

        this.setState({
            loading: false,
            order: response.data.order
        });
    };

    handleOnOrderUpdated = (updatedOrder) => {
      const {order} = this.state;
      const resultOrder = {
        ...order,
        ...updatedOrder,
      };
      this.setState({
        order: resultOrder,
      });
    }

    renderOrderSubHeader = () => {
        const { order } = this.state;
        const { orderTypeId, store, shippingAddress } = order;

        if (order.isCancelled) {
          return (<p>Cancelled</p>);
        }

        if (order.isRefunded) {
          return (<p>Refunded</p>);
        }

        const subHeaderByOrderTypeId = {};

        subHeaderByOrderTypeId[orderTypeIds.delivery] = () => {
          if (this.orderInProgress()) {
            return null;
          }

          return (
            <p>
              Delivered
              <span>
              to
                {' '}
                {shippingAddress.address1}
              </span>
            </p>
          );
        }

        subHeaderByOrderTypeId[orderTypeIds.pickup] = () => {
          if (this.orderInProgress()) {
            return null;
          }

          return (
            <p>
              Picked up
              <span>
                at
                {' '}
                {store.address}
              </span>
            </p>
          );
        }

        subHeaderByOrderTypeId[orderTypeIds.shipping] = () => (
          <p>
            { this.orderInProgress() ? 'Ship' : 'Shipped' }
            <span>
              to
              {' '}
              {shippingAddress.address1}
            </span>
          </p>
        );

        subHeaderByOrderTypeId[orderTypeIds.eGiftCard] = () => (
          <p>
            Gift card
          </p>
        )

        return subHeaderByOrderTypeId[orderTypeId]();
    };

    renderTrackingWidget = () => {
      const { order } = this.state;

      return (
        <div className={styles.trackingMapContainer}>
          <div className={styles.trackingMap}>
            <OrderTrackingWidget order={order} mode={orderTrackingWidgetModes.orderDetails}>
              <OrderDetailsWidget order={order} onOrderUpdated={this.handleOnOrderUpdated} />
            </OrderTrackingWidget>
          </div>
          <div className={styles.orderTotals}>
            <OrderTotalsWidget order={order} />
          </div>
        </div>
      );
    }

    render() {
        const { loading, order } = this.state;

        if (loading) {
            return (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            );
        }

        return (
          <div className={`${styles.pageWrapper} text-dark dark:text-white mb-16`}>
            <div className={`${styles.pageContainer} max-w-screen-desktop`}>
              <h1>Order Details</h1>

              {this.renderOrderSubHeader()}
              <span>
                Order #
                {order.id}
              </span>

              {this.renderTrackingWidget()}
            </div>
          </div>
        );
    }
}

export default OrderDetails;
